<style>
.btn-gohome {
  width: 100%;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  color: #fff !important;
  background-color: #ff5011;
}
.btn-gohome::disable {
  width: 100%;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  color: #fff !important;
  background-color: #ffa483;
}
.btn-gohome:hover {
  color: #fff;
}
body.default-style #app-content #illness-review .blue-bg {
  background-image: linear-gradient(0deg, #005187 0%, #00457b 100%);
  color: #fff;
  text-align: center;
  padding: 0;
}
.btn-dropdown {
  text-align: left;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.arrow-dropdown{
  float: right;
   color: #ff5011; 
  transition: all 0.4s ease;
}

.arrow-dropdown.active{
  transform: rotateZ(-180deg);
}
::-webkit-input-placeholder {
  text-align: left;
}

:-moz-placeholder {
  text-align: left;
}
.form-group {
  position: relative;
}
.form-group i {
  color: #ff5011;
  position: absolute;
  right: 15px;
  top: 40px;
}
.list-item {
  cursor: pointer;
}
#messages {
  display: none;
}
</style>
<template>
  <div id="illness-review" style="min-height: 80vh">
    <div class="blue-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 pt-3 my-4" style="max-width: 600px">
            <h3 id="title-feefo">
              {{$t("title")}}
            </h3>
            <div id="messages">
              <h3 class="success-message" v-if="isRedeemSuccess">
                {{$t("successMessage")}}
              </h3>
              <h3 class="success-message" v-else>
                {{$t("errorMessage")}}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-4 px-4" style="max-width: 40em; margin: auto;" id="form-feefo">
      <p>
        {{$t("title2")}}
      </p>
      <div class="form-group my-4">
        <label for="PhoneNumber">{{$t("label_phone")}}</label>
        <input
          type="tel"
          class="form-control phone-form"
          id="PhoneNumber"
          placeholder="Ex : 0812 3456 7890"
          v-model="phoneNumber"
          maxlength="14"
        />
        <i class="glyphicons" :class="classPhoneIcon"></i>
      </div>
      <div class="form-group my-4">
        <div class="dropdown">
          <label for="dropdownEwallet">{{$t("label_ewallet")}}</label>
          <button
            class="btn btn-dropdown dropdown-toggle"
            type="button"
            id="dropdownEwallet"
            data-toggle="dropdown"
            @click="arrow = !arrow"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span v-if="voucherType == null">{{$t("ewallet")}}</span>
            <span v-if="voucherType == 'Ovo Rp25.000'"
              ><img
                src="images/ovo.svg"
                width="22"
                height="22"
              />
              OVO</span
            >
            <span v-if="voucherType == 'GoPay Rp25.000'"
              ><img
                src="images/gopay.svg"
                width="22"
                height="22"
              />
              GoPay</span
            >
            <span
              v-bind:class="[ !arrow ? 'active' : '']"
              class="glyphicons glyphicons-chevron-down mt-1 arrow-dropdown">
            </span>
          </button>

          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style="width: 100%"
          >
            <li class="dropdown-item list-item" @click="addVoucher('Ovo Rp25.000')">
              <img
                src="images/ovo.svg"
                width="22"
                height="22"
              />
              OVO
            </li>
            <li class="dropdown-item list-item" @click="addVoucher('GoPay Rp25.000')">
              <img
                src="images/gopay.svg"
                width="22"
                height="22"
              />
              GoPay
            </li>
          </ul>
        </div>
      </div>
      <button
        class="btn btn-gohome btn-block"
        data-selenium-name="floating-buynow"
        @click="submit()"
        style="color:#fff;"
        :disabled="isDisabled"
      >
        <strong>{{$t("submit")}}</strong>
      </button>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";

export default {
  name: "thankyou",
  mixins: [Mixin],
  data() {
    return {
      Global,
      arrow: false,
      classPhoneIcon: "",
      voucherType: null,
      phoneNumber: null,
      isDisabled: true,
      isRedeemSuccess: false,
      validation: {
        voucherType: null,
        phoneNumber: null,
      },
    };
  },
  watch: {
    phoneNumber: function(value) {
      this.phoneNumber = this.filterNumberOnly(value);
      this.validation.phoneNumber = this.phoneNumber;
      if(this.phoneNumber.length >= 10) {
          this.classPhoneIcon = "glyphicons-ok";
      } else {
        this.classPhoneIcon = "glyphicons-warning-sign";
      }

    },
    voucherType: function(value) {
      this.validation.voucherType = value;
    },
    validation: {
      deep: true,
      handler: function(value) {
        if (value.phoneNumber.length > 10 && value.voucherType !== null) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    }
  },
  created() {
    this.loading("hide");
  },
  mounted() {
    window.scrollTo(0, 0);
    $('.tooltip').hide();
  },
  methods: {
    async submit() {
      this.loading("show");
      const payload = {
        item : {
          vouchertype: this.voucherType,
          phonenumber: this.phoneNumber,
          rewardId: Global.rewardId
        }
      }
    
      try {
        const response = await this.reedemFeefoAction(payload);
        this.loading("hide");
        if(response.data.success == true) {
          this.isRedeemSuccess = true;
          $("#title-feefo").hide();
          $("#messages").show();
          $("#form-feefo").css("display", "none");
        } else if (response.data.success == false && response.data.message.includes("redeemed")){
          this.isRedeemSuccess = false;
          $("#title-feefo").hide();
          $("#messages").show();
          $("#form-feefo").css("display", "none");
        } else {
          this.errorModal(this.$t("error.connection.message"));
        }

      } catch(error) {
        this.loading("hide");
        this.errorModal(this.$t("error.connection.message"));
      }
      
    },

    addVoucher(value){
      var vm = this;
      vm.voucherType = value
    },

    clickButton(){
      var aria = $('#dropdownEwallet').attr('aria-expanded');
      console.log(aria);
      if (aria == false) {
        $('.arrow-dropdown').addClass('active');
      }else if(aria == true){
        $('.arrow-dropdown').removeClass('active');
      }
    },

    filterNumberOnly(value) {
      const onlyNumber = value.replace(/\D/g, '');
      return onlyNumber;
    }
  },
};
</script>
<i18n>
{
  "en": {
    "thankyou": "Your Payment has been Processed ",
    "title": "Enter your phone number to get GoPay/OVO balance",
    "title2" : "Please make sure the number you entered is right and connected to GoPay/OVO",
    "label_phone" : "Phone Number",
    "label_ewallet" : "Choose e-wallet to receive the balance",
    "ewallet" : "Choose e-wallet",
    "message4" : "If you have any questions or need further information, please contact us at ",
    "no_contact" : "021 5089 0822.",
    "submit" : "Submit",
    "successMessage" : "Succeed! We will process the delivery of the max. GoPay/OVO balance. within 7 working days.",
    "errorMessage": "Oops! You have entered this mobile number. Rewards can only be redeemed once."
  },
  "id": {
    "thankyou": "Pembayaran Berhasil Diproses ",
    "title": "Silakan masukkan nomor handphonemu untuk mendapatkan saldo GoPay/OVO",
    "title2" : "Pastikan nomor yang kamu masukkan sudah benar dan telah terhubung dengan GoPay/OVO",
    "label_phone" : "Nomor Handphone",
    "label_ewallet" : "Pilih e-wallet untuk terima saldo",
    "ewallet" : "Pilih e-wallet",
    "message4" : "Jika kamu memiliki pertanyaan atau butuh informasi lebih lanjut, silakan menghubungi kami di ",
    "no_contact" : "021 5089 0822.",
    "submit" : "Kirim",
    "successMessage" : "Berhasil! Kami akan memproses pengiriman saldo GoPay/OVO maks. dalam 7 hari kerja.",
    "errorMessage": "Ups! Anda telah memasukkan nomor ponsel ini. Hadiah hanya dapat ditukarkan satu kali."
  },
}
</i18n>